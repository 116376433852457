import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BlogPost from '../blog-post';
import ShareButton from '../share-button';
import queryString from 'query-string';
import styles from './blog-browser.module.css';

const BlogBrowser = (props) => {
  const [blogPostState, setBlogPostState] = useState({
    isOpen: false,
    blog: {}
  });

  useEffect(() => {
    if(queryString.parse(window.location.search).post && props.blogs && props.blogs.length > 0 
      && props.blogs.find(blog => blog.guid._cdata === queryString.parse(window.location.search).post) !== null) {
        setBlogPostState({
          ...blogPostState,
          isOpen: true,
          blog: props.blogs.find(blog => blog.guid._cdata === queryString.parse(window.location.search).post)
        })
    }
  }, [props.blogs]);

  const [blogCount, setBlogCount] = useState(8);
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const handleReadMore = (index) => {
    setBlogPostState({
      ...blogPostState,
      isOpen: true,
      blog: props.blogs[index]
    })
  }

  const handleCloseBlog = () => {
    setBlogPostState({
      ...blogPostState,
      isOpen: false
    });
  }

  return (
    <Box className={styles.browser}>
      {props.blogs.length > 0 &&
        <BlogPost blog={blogPostState.blog} open={blogPostState.isOpen} onClose={handleCloseBlog}/>
      }
      <Typography variant="h6" component="h6" gutterBottom>The NBP</Typography>
      <Typography variant="body1">
        Follow our blog to stay in the know on the latest news of The Nostalgia Blast Podcast.
        Show updates and episode extras can all be found here.
      </Typography>
      <Grid container className={styles.container}>
        { props.blogs.slice(0,blogCount).map((blog, index) => {
            const date = new Date(blog.pubDate._text);
            const dateString = monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
            return (
              <Grid key={index} item xs={12} sm={4}>
                <Card className={styles.card}>
                  <CardActionArea onClick={() => handleReadMore(index)} className={styles.actionArea}>
                    <CardHeader title={blog.title._text} subheader={dateString}/>
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p" noWrap>
                        {blog['itunes:subtitle']._cdata}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions disableSpacing className={styles.actions}>
                    <ShareButton shareData={{title: blog.title._text, text: blog['itunes:subtitle']._cdata, url: 'https://nbpodcast.com/blog?post=' + blog.guid._cdata}} id={blog.guid._cdata}/>
                    <Button size="small" color="default" className={styles.button} onClick={() => handleReadMore(index)}>
                      Read More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
      {
        blogCount < props.blogs.length &&
        <Button className={styles.wideButton} color="default" variant="contained" onClick={() => setBlogCount(blogCount + 8)}>See More</Button>
      }
    </Box>
  );
}

export default BlogBrowser;