import App from './app';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    podcast: state.podcast,
    isRetro: state.style.isRetro,
    announcement: state.podcast.rss.channel.item?state.podcast.rss.channel.item.find(item => item.title._text.toLowerCase() === 'announcement' 
      && !(item['itunes:keywords'] && item['itunes:keywords']._text && item['itunes:keywords']._text.indexOf('inactive')>=0)):null
  }
}

export default connect(mapStateToProps)(App) ;