import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  title: {
    fontFamily: 'MajorMonoDisplay',
    marginBottom: '1em'
  }
});

const NewsletterSubscribe = (props) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    email: '',
    nameFirst: '',
    nameLast: ''
  })

  return (
      <form autoComplete={false}>
        <Card>
          <CardContent>
            <Typography variant="h6" className={classes.title}>Subscribe to our newsletter</Typography>
            <Typography variant="body1">Sign up to receive an email as soon as new podcasts, blog posts, or import podcast news drops!</Typography>
            <TextField label="Email" value={formState.email} onChange={(event) => setFormState({...formState, email: event.target.value})} required/>
            <br/>
            <TextField label="First Name" value={formState.nameFirst} onChange={(event) => setFormState({...formState, nameFirst: event.target.value})}/>
            <br/>
            <TextField label="Last Name" value={formState.nameLast} onChange={(event) => setFormState({...formState, nameLast: event.target.value})}/>
          </CardContent>
          <CardActions>
            <Button color="secondary">Subscribe</Button>
          </CardActions>
        </Card>
      </form>
  );
}

export default NewsletterSubscribe;