import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import styles from './about.module.css';

const About = (props) => {
  return (
    <div className={styles.main}>
      <Container maxWidth="lg">
        <Paper className={styles.card} square elevation={3}>
          <Typography variant="subtitle2">{props.description}</Typography>
          <Typography variant="overline" className={styles.author}>
              <a className={styles.link} href={props.mailTo}>
                  - {props.author}
              </a>
          </Typography>
          <Typography variant="caption" className={styles.tags}>Tags: {props.tags.map(category => {
              return (category._attributes.text + ', ');
          })}</Typography>
        </Paper>
      </Container>
    </div>
  );
}

export default About;