import Header from './header';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styleOperations } from '../../redux/ducks/style';

const mapDispatchToProps = dispatch => {
    return {
        onToggle: () => {
            dispatch(styleOperations.toggleRetro())
        },
        toggleDrawer: () => {
            dispatch(styleOperations.toggleDrawer())
        }
    }
}

const mapStateToProps = state => {
    return {
        isRetro: state.style.isRetro,
        isVisible: state.style.isDrawerOpened,
        title: state.podcast.rss && state.podcast.rss.channel && state.podcast.rss.channel.title?state.podcast.rss.channel.title._text:''
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));