import Contact from './contact';
import {connect} from 'react-redux';
import {emailOperations} from '../../redux/ducks/email';

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (email) => {
      dispatch(emailOperations.sendEmail(email));
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.email.isLoading,
    message: state.email.message
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);