const retroReviews = {
    Brian: {
        wins: 3,
        losses: 3,
        gamesPlayed: 6,
        slogan: '',
        5: {
            name: 'NBP Ep 5: Totally Sweet 90\'s',
            result: -1,
            home: 'Mrs. Doubtfire',
            away: 'Independence Day',
            homeRating: 7.1,
            awayRating: 6.4,
            ratingUsed: 'RT',
            winner: 'Mrs. Doubtfire'
        },
        7: {
            name: 'NBP Ep 7: The Good Ol\' Days of LAN Parties',
            result: -1,
            home: 'Mighty Morphin Power Rangers: The Movie',
            away: 'Mortal Kombat',
            homeRating: 5.2,
            awayRating: 5.8,
            ratingUsed: 'IMDB',
            winner: 'Mortal Kombat'
        },
        9: {
            name: 'NBP Ep 9: Take Me Out To A 90s Ballgame',
            result: 1,
            home: 'Angels in the Outfield',
            away: 'Rookie of the Year',
            homeRating: 6.1,
            awayRating: 6.0,
            ratingUsed: 'IMDB',
            winner: 'Angels in the Outfield'
        },
        10: {
            name: 'NBP Ep 10: A Very 90s Halloween Special',
            result: -1,
            home: 'Young Frankenstein',
            away: 'The Nightmare Before Christmas',
            homeRating: 9.3,
            awayRating: 9.5,
            ratingUsed: 'RT',
            winner: 'The Nightmare Before Christmas'
        },
        12: {
            name: 'NBP Ep 12: Shop \'Til You Drop',
            result: 1,
            home: 'Jumanji',
            away: 'The Mask',
            homeRating: 7.0,
            awayRating: 6.9,
            ratingUsed: 'IMDB',
            winner: 'Jumanji'
        },
        20: {
            name: 'NBP Ep 20: Loving the 90s Quiz Show!',
            result: 1,
            home: 'The Truman Show',
            away: 'Quiz Show',
            homeRating: 8.1,
            awayRating: 7.5,
            ratingUsed: 'IMDB',
            winner: 'The Truman Show'
        },
        episodes: [5,7,9,10,12,20]
    },
    Drew: {
        wins: 5,
        losses: 3,
        gamesPlayed: 8,
        slogan: 'Don\'t forget the Disney Rule; Disney movies always get the highest rating!',
        6: {
            name: 'NBP Ep 6: Go Play Outside!',
            result: -1,
            home: 'Twister',
            away: 'Fly Away Home',
            homeRating: 6.3,
            awayRating: 6.8,
            ratingUsed: 'IMDB',
            winner: 'Mrs. Doubtfire'
        },
        8: {
            name: 'NBP Ep 8: Let\'s Go To The Mall!',
            result: 1,
            home: 'Free Willy',
            away: 'Homeward Bound: The Incredible Journey',
            homeRating: 5.9,
            awayRating: 6.9,
            ratingUsed: 'IMDB',
            winner: 'Homeward Bound: The Incredible Journey'
        },
        14: {
            name: 'NBP Ep 14: Who Doesn\'t Want To Be A Millionaire?',
            result: 1,
            home: 'Tin Cup',
            away: 'Happy Gilmore',
            homeRating: 6.3,
            awayRating: 7.0,
            ratingUsed: 'IMDB',
            winner: 'Happy Gilmore'
        },
        16: {
            name: 'NBP Ep 16: It\'s a Snow Day!!',
            result: -1,
            home: 'DuckTales the Movie: Treasure of the Lost Lamp',
            away: 'A Goofy Movie',
            homeRating: 6.9,
            awayRating: 6.8,
            ratingUsed: 'IMDB',
            winner: 'DuckTales the Movie: Treasure of the Lost Lamp'
        },
        17: {
            name: 'NBP Ep 17: Now This is Podcasting!',
            result: 1,
            home: 'Star Wars Episode 2: The Attack of the Clones',
            away: 'Star Wars Episode 8: The Last Jedi',
            homeRating: 5.6,
            awayRating: 4.4,
            ratingUsed: 'RT',
            winner: 'Star Wars Episode 2: The Attack of the Clones'
        },
        18: {
            name: 'NBP Ep 18: Merry Christmas 2019!',
            result: -1,
            home: 'Jingle All the Way',
            away: 'The Santa Clause',
            homeRating: 5.6,
            awayRating: 6.5,
            ratingUsed: 'IMDB',
            winner: 'The Santa Clause'
        },
        19: {
            name: 'NBP Ep 19: Book Fairs and Fundraisers!',
            result: 1,
            home: 'Rock-a-Doodle',
            away: 'The Rescuers Down Under',
            homeRating: 6.1,
            awayRating: 6.9,
            ratingUsed: 'IMDB',
            winner: 'The Rescuers Down Under'
        },
        21: {
            name: 'NBP Ep 21: V Blocks of the 90s!',
            result: 1,
            home: 'The Indian in the Cupboard',
            away: 'Man of the House',
            homeRating: 6.0,
            awayRating: 5.2,
            ratingUsed: 'IMDB',
            winner: 'The Indian in the Cupboard'
        },
        episodes: [6,8,14,16,17,18,19,21]
    },
    players: ['Brian', 'Drew']
}

export default retroReviews;