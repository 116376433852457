import React, { Component } from 'react';
import { 
    CartesianGrid, 
    LineChart, 
    Line, 
    Legend,
    ReferenceLine,
    Tooltip, 
    XAxis, 
    YAxis 
} from 'recharts';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Player from './player';
import { withStyles } from '@material-ui/core/styles';
import styles from './stats.module.css';

class Stats extends Component {
    render() {
        const { classes } = this.props;
        let data = [{Brian:0, Drew: 0, episode:0}];
        if(this.props.stats.retroReviews.players){
            this.props.stats.retroReviews.players.map(player => {
                let wins = 0;
                this.props.stats.retroReviews[player].episodes.map((episode,index) => {
                    let point = {};
                    if(this.props.stats.retroReviews[player][episode].result === 1)
                        wins+=this.props.stats.retroReviews[player][episode].result;
                    point.result = this.props.stats.retroReviews[player][episode].result;
                    this.props.stats.retroReviews[player][episode].gamesPlayed = index+1;
                    this.props.stats.retroReviews[player][episode][player] = wins===0?0:wins/this.props.stats.retroReviews[player][episode].gamesPlayed;
                    this.props.stats.retroReviews[player][episode].episode = episode;
                    data.push(this.props.stats.retroReviews[player][episode]);
                });
            });
            data.sort((a,b) => {
                if(a.episode < b.episode)
                    return -1;
                return 1;
            })
            console.log(data);
        }
        return(
            <Container className={classes.root}>
                <Typography variant="h4">Retro Reviews</Typography>
                <Typography variant="subtitle">It's everybody's favorite segment! In the Retro Review, Drew or Brian are 
                faced with choosing which of two 90's movies received a higher rating on IMDB! In the case of a tie, the Rotten Tomatoes
                rating is used as a tie-breaker!</Typography>
                <Typography variant="h6">Retro Review Stats</Typography>
                <Typography variant="subtitle">The chart below shows the Retro Review win percentage for Drew and Brian by episode.</Typography>
                <Paper className={styles.chartContainer}>
                    <LineChart className={styles.chart} height={350} width={1000} data={data}>
                        <XAxis dataKey="episode" />
                        <YAxis unit="%"/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend/>
                        <CartesianGrid strokeDasharray="3 3" />
                        <ReferenceLine y={0.5} stroke="red" strokeDasharray="3 3"/>
                        <Line connectNulls type="monotone" dataKey="Brian" stroke="limegreen" fill="limegreen"/>
                        <Line connectNulls type="monotone" dataKey="Drew" stroke="purple" fill="purple"/>
                    </LineChart>
                </Paper>
                <Typography variant="h6">Individual Performance</Typography>
                <Typography variant="subtitle">For an episode-by-episode breakdown of each Retro Review, see the data below.</Typography>
                {
                    this.props.stats.players.map(player => {
                        this.props.stats[player].name = player;
                        return (<Player player={this.props.stats[player]}/>);
                    })
                }
            </Container>
        );
    }
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload[0].payload.episode !== 0) {
      return (
        <div className={styles.label}>
            <p><b>{payload[0].payload.name}</b></p>
            <p>
                <i>{payload[0].payload.home}</i>
                <span>&nbsp;({payload[0].payload.homeRating}) vs.&nbsp;</span>
                <i>{payload[0].payload.away}</i>
                <span>&nbsp;({payload[0].payload.awayRating})</span>
            </p>
            <p>Result: {payload[0].payload.result === 1?'Win':'Loss'}</p>
            <p>Rating Used: {payload[0].payload.ratingUsed}</p>
        </div>
      );
    }
  
    return null;
};

const useStyles = theme => ({
    root: {
        '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
        },
    },
});

export default withStyles(useStyles)(Stats);