import React from 'react';
import AppLinks from '../app-links';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory, useLocation } from 'react-router-dom'
import constants from '../../constants';
import styles from './header.module.css';

const Header = (props) => {
    const history = useHistory();
    const location = useLocation();

    const handleTitleClick = () => {
      if(location.pathname !== constants.routes.home)
        history.push(constants.routes.home)
    }

    return (
        <AppBar position="fixed" color="transparent" className={styles.headerContainer}>
          <Toolbar>
            <IconButton edge="start" onClick={props.toggleDrawer} color="secondary">
              <MenuIcon fontSize="large" alt="hamburger icon"/>
            </IconButton>
            <Typography variant="h6" className={location.pathname === constants.routes.home?styles.title:[styles.title, styles.pointer].join(' ')} style={{fontFamily: 'MajorMonoDisplay'}} onClick={handleTitleClick}>{props.title}</Typography>
            <Switch className={styles.switch} color="secondary" checked={props.isRetro} onChange={props.onToggle} alt="Switch to Retro style"/>
          </Toolbar>
          <Drawer anchor="left" open={props.isVisible} onClose={props.toggleDrawer}><AppLinks/></Drawer>
        </AppBar>
    );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  isRetro: PropTypes.bool.isRequired
}

export default Header;
