import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ShareButton from '../share-button';
import playIcon from '../../images/play-icon.svg';
import styles from './audio.module.css';

class Audio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: false,
            copyTooltip: 'Copy Link',
            isCopied: false
        };
        if(props.autoplay)
          setTimeout(() => {this.setState({...this.state, isOpened: true})}, 1000)
    }

    handlePlay() {
        this.setState({
            ...this.state,
            isOpened: true
        })
    }

    handleStopPropagation = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if(event.nativeEvent) {
        event.nativeEvent.preventDefault();
        event.nativeEvent.stopPropagation();
      }
    }

    handleClick = (e) => {
      const copyText = document.getElementById(this.props.id + '-link');
      copyText.style.display = 'block';
      copyText.select();
      document.execCommand('copy');
      copyText.style.display = 'none';
      this.handleCopyFeedback();
    }

    handleCopyFeedback = () => {
      this.setState({
        ...this.state,
        isCopied: true
      })
    }

    handleCloseSnackbar = () => {
      this.setState({
        ...this.state,
        isCopied: false
      })
    }

    render() {
      const { classes } = this.props;
      return (
        <div className={styles.content + ' ' + classes.root} onClick={this.handleStopPropagation}>
          { this.state.isOpened
            ?<audio className={styles.audio} controls controlsList="nodownload" autoPlay>
                <source src={this.props.enclosure._attributes.url} type={this.props.enclosure._attributes.type}></source>
            </audio>
            :<Button color="primary" className={styles.roundButton} variant="contained" onClick={() => this.handlePlay()}>
              <img src={playIcon} alt="play"/>Play
            </Button>
          }
          <a href={this.props.enclosure._attributes.url.split("?")[0]} type="mpeg" target="_blank" download>
              <Button color="secondary" className={styles.download} variant="outlined">Download</Button>
          </a>
          <ShareButton shareData={{title: this.props.title, text: 'Check out this episode of the Nostalgia Blast Podcast!', url: 'https://nbpodcast.com?ep='+this.props.id}} id={this.props.id}/>
        </div>
      );
  }
}

Audio.propTypes = {
  enclosure: PropTypes.object.isRequired
}

const useStyles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});

export default withStyles(useStyles)(Audio);
