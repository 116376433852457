import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';

const ShareButton = (props) => {
  const [buttonState, setButtonState] = useState({
    isOpen: false,
    isCopied: false
  })

  const handleOpen = () => {
    setButtonState({
      ...buttonState,
      isOpen: true
    })
  }

  const handleClose = () => {
    setButtonState({
      ...buttonState,
      isOpen: false
    })
  }

  const handleShare = async () => {
    try {
      await navigator.share(props.shareData)
    } catch(err) {
      console.log('Error: ' + err)
    }
  }

  const handleCopy = (e) => {
    const copyText = document.getElementById(props.id + '-link');
    copyText.style.display = 'block';
    copyText.select();
    document.execCommand('copy');
    copyText.style.display = 'none';
    handleCopyFeedback();
  }

  const handleCopyFeedback = () => {
    setTimeout(() => {
      setButtonState({
        ...buttonState,
        isCopied: true
      })
    }, 100);
  }

  const handleCloseSnackbar = () => {
    setButtonState({
      ...buttonState,
      isCopied: false
    })
  }

  return (
    <div>
      <SpeedDial ariaLabel="Share" icon={<SpeedDialIcon icon={<ShareIcon/>} openIcon={<CloseIcon/>}/>} onClose={handleClose} onOpen={handleOpen} open={buttonState.isOpen} direction="right">
        <SpeedDialAction key={0} icon={<ShareIcon/>} tooltipTitle={"Share"}  onClick={handleShare}/>
        <SpeedDialAction key={1} icon={<CopyIcon/>} tooltipTitle={"Copy Link"}  onClick={handleCopy}/>
      </SpeedDial>
      <input id={props.id + '-link'} type="text" readOnly style={{display:'none'}} value={props.shareData.url}/>
      <Snackbar open={buttonState.isCopied} message={'Link copied!'} onClose={handleCloseSnackbar} autoHideDuration={1000}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        />
    </div>
  );
}

export default ShareButton;