import retroReviews from '../../retroReviewState';
import types from '../../types';

export const requestStats = () => {
  return {
    type: types.REQUEST_STATS,
    isLoading: true
  }
}

export const getStats = () => {
  return dispatch => {
    dispatch(requestStats());
    return dispatch(receiveStats(retroReviews));
  }
}

export const receiveStats = (stats) => {
  return {
    type: types.RECEIVE_STATS,
    response: stats
  }
}