import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {Link} from 'react-router-dom';
import properties from '../../properties';
import * as icons from '../../images/icons';
import constants from '../../constants';
import baby from '../../images/dancing-baby.gif';
import styles from './footer.module.css';

class Footer extends Component {

  render() {
    return (
        <div className={this.props.isRetro?[styles.footerRetro, styles.footer].join(' '):styles.footer}>
            { this.props.isRetro && false &&
              <div>
                <img src={baby} alt="left dancing-baby.gif" className={styles.babyLeft}/>
                <img src={baby} alt="right dancing-baby.gif" className={styles.babyRight}/>
              </div>
            }
            <Divider light/>
            <div className={styles.header}>Follow Us</div>
            <IconButton className={styles.logo}>
                <a href={properties.footer.itunes}>
                    <img src={icons.itunesLogo} alt="itunes"/>
                </a>
            </IconButton>
            <IconButton className={styles.logo}>
                <a href={properties.footer.spotify}>
                    <img src={icons.spotifyLogo} alt="spotify"/>
                </a>
            </IconButton>
            <IconButton className={styles.logo}>
                <a href={properties.footer.instagram}>
                    <img src={icons.instagramLogo} alt="instagram"/>
                </a>
            </IconButton>
            <IconButton className={styles.logo}>
                <a href={properties.footer.twitter}>
                    <img src={icons.twitterLogo} alt="twitter"/>
                </a>
            </IconButton>
            <IconButton className={styles.logo}>
                <a href={properties.footer.youtube}>
                    <img src={icons.youtubeLogo} alt="youtube"/>
                </a>
            </IconButton>
            <div>
            { constants.routesArray.filter(route => route.active).map((route,index) => {
                return (
                  <Link key={index} to={route.path}>
                    <Button className={styles.link} color="secondary">{route.name}</Button>
                  </Link>
                )
              })
            }
            </div>
            <div className={styles.copyright}>{ '© 2019-' + new Date().getFullYear() + ' The Nostalgia Blast Podcast'}</div>
        </div>
    );
  }
}

Footer.propTypes = {
  channel: PropTypes.object.isRequired
}

export default Footer;
