import MediaPlayerHeader from './media-player-header';
import {connect} from 'react-redux';
import { styleOperations } from '../../redux/ducks/style';

const mapDispatchToProps = dispatch => {
  return {
    onToggle: () => {
      dispatch(styleOperations.toggleRetro())
    },
    toggleDrawer: () => {
      dispatch(styleOperations.toggleDrawer())
    }
  }
}

const mapStateToProps = state => {
  return {
    episodes: state.podcast && state.podcast.rss && state.podcast.rss.channel && state.podcast.rss.channel.item?state.podcast.rss.channel.item.filter(item => item && item.enclosure && item.enclosure._attributes && item.enclosure._attributes.type && item.enclosure._attributes.type === 'audio/mpeg'):[],
    isRetro: state.style.isRetro,
    isVisible: state.style.isDrawerOpened
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaPlayerHeader);