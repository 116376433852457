import types from '../../types';
import initialState from '../../initialState';

function stats(state = initialState.stats, action) {
  switch (action.type) {
    case types.REQUEST_STATS:
      return {
        ...state,
        isLoading: true
      }
    case types.RECEIVE_STATS:
      //console.log(action.response);
      return {
        ...state,
        isLoading: false,
        ...action.response
      }
    default:
      return state
  }
}

export default stats;