import AppLinks from './app-links';
import { connect } from 'react-redux';
import { styleOperations } from '../../redux/ducks/style';

const mapDispatchToProps = dispatch => {
    return {
        toggleDrawer: () => {
            dispatch(styleOperations.toggleDrawer())
        }
    }
}

const mapStateToProps = state => {
    return {
        isVisible: state.style.isDrawerOpened
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLinks);