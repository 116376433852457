import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Audio from '../audio';
import DOMPurify from 'dompurify';
import styles from './media.module.css';

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isOpened: false
    };
  }

  handleToggleDescription = () => {
    this.setState({
      isOpened: !this.state.isOpened
    });
  }

  render() {
    const date = new Date(this.props.item.pubDate._text);
    const dateString = (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();
    const description = {__html:DOMPurify.sanitize(this.props.item.description._cdata)};

    return (
      <div>
            <Card className={styles.card} onClick={this.handleToggleDescription} square>
              <CardMedia component="img" className={styles.thumbnail} image={this.props.item['itunes:image']._attributes.href} alt="podcast thumbnail" title="podcast thumbnail"/>
              <CardHeader title={this.props.item.title._text} subheader={dateString}/>
              <CardContent>
                <Typography variant="body1" className={this.state.isOpened?styles.description:[styles.description, styles.hidden].join(' ')} dangerouslySetInnerHTML={description}></Typography>
                {this.state.isOpened?<ExpandLessIcon className={styles.dropdown}/>:<ExpandMoreIcon className={styles.dropdown}/>}
              </CardContent>
              <CardActions>
                <Audio id={this.props.item.guid._cdata} title={this.props.item.title._text} enclosure={this.props.item.enclosure}/>
              </CardActions>
            </Card>
        </div>
    );
  }
}

Media.propTypes = {
  item: PropTypes.object.isRequired
}

export default Media;
