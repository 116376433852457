import React, {useEffect, useState} from 'react';
import AppLinks from '../app-links';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import styles from './media-player-header.module.css';

const MediaPlayerHeader = (props) => {
  const [mediaPlayerState, setMediaPlayerState] = useState({
    isPlaying: false,
    episodeIndex: props.startGuid && props.episodes && props.episodes.length > 0?props.episodes.findIndex(episode => episode.guid._cdata === props.startGuid):0,
    direction: 'left',
    animate: true,
    isAudioLoaded: false
  });

  useEffect(() => {
    if(props.startGuid && props.episodes && props.episodes.length > 0) {
      setMediaPlayerState({
        ...mediaPlayerState,
        episodeIndex: props.episodes.findIndex(episode => episode.guid._cdata === props.startGuid)
      });
    }
  },[props.startGuid, props.episodes])

  const season = props.episodes.length>mediaPlayerState.episodeIndex?props.episodes[mediaPlayerState.episodeIndex]['itunes:season']._text:'';
  const episode = props.episodes.length>mediaPlayerState.episodeIndex?props.episodes[mediaPlayerState.episodeIndex]['itunes:episode']._text:'';
  const audioUrl = props.episodes.length > mediaPlayerState.episodeIndex?props.episodes[mediaPlayerState.episodeIndex].enclosure._attributes.url:'';
  

  const handlePlayToggle = () => {
    const audioEl = document.getElementById('media-player-audio'); 
    if(!mediaPlayerState.isPlaying) {
      if(audioEl.getAttribute('src') !== audioUrl) {
        audioEl.setAttribute('src', audioUrl);
      }
      setTimeout(() => {audioEl.play()}, 500);
    } else {
      audioEl.pause();
    }

    setMediaPlayerState({
      ...mediaPlayerState,
      isPlaying: !mediaPlayerState.isPlaying,
      isAudioLoaded: true
    })
  }

  const handlePrevious = () => {
    const audioEl = document.getElementById('media-player-audio'); 
    audioEl.pause();
    audioEl.currentTime = 0;

    setMediaPlayerState({
      ...mediaPlayerState,
      direction: 'left',
      animate: false,
      isAudioLoaded: false
    });

    setTimeout(() => {
      setMediaPlayerState({
        ...mediaPlayerState,
        isPlaying: false,
        episodeIndex: mediaPlayerState.episodeIndex + 1>=props.episodes.length?0:mediaPlayerState.episodeIndex + 1,
        animate: true,
        direction: 'right'
      })
    }, 300);
  }

  const handleNext = () => {
    const audioEl = document.getElementById('media-player-audio'); 
    audioEl.pause();
    audioEl.currentTime = 0;

    setMediaPlayerState({
      ...mediaPlayerState,
      direction: 'right',
      animate: false,
      isAudioLoaded: false
    });

    setTimeout(() => {
      setMediaPlayerState({
        ...mediaPlayerState,
        isPlaying: false,
        episodeIndex: mediaPlayerState.episodeIndex - 1<0?props.episodes.length - 1:mediaPlayerState.episodeIndex - 1,
        animate: true,
        direction: 'left'
      })
    }, 300);
  }

  return (
    <div className={styles.container}>
      <AppBar position={'fixed'} color="transparent" elevation={0}>
        <Toolbar>
          <IconButton edge="start" onClick={props.toggleDrawer} color="secondary">
            <MenuIcon fontSize="large" alt="hamburger icon"/>
          </IconButton>
          <Switch className={styles.switch} color="secondary" checked={props.isRetro} onChange={props.onToggle} alt="Switch to Retro style"/>
        </Toolbar>
        <Drawer anchor="left" open={props.isVisible} onClose={props.toggleDrawer}><AppLinks/></Drawer>
      </AppBar>
      <Grid className={styles.headerContainer} container direction="row" justify="center" alignItems="flex-end">
        <Grid item xs={12} className={styles.player}>
          <IconButton edge="start" onClick={handlePrevious}>
            <SkipPreviousIcon fontSize="large" alt="previous icon"/>
          </IconButton>
          { mediaPlayerState.isPlaying?
          <IconButton edge="start" onClick={handlePlayToggle}>
            <PauseIcon fontSize="large" alt="pause icon" />
          </IconButton>:
          <IconButton edge="start" onClick={handlePlayToggle}>
            <PlayIcon fontSize="large" alt="play icon"/>
          </IconButton>
          }
          <IconButton edge="start" onClick={handleNext}>
            <SkipNextIcon fontSize="large" alt="next icon"/>
          </IconButton>
          <audio id="media-player-audio"/>
          <Slide direction={mediaPlayerState.direction} in={mediaPlayerState.animate}>
            <Typography variant="h5" className={styles.title}>
              season {season} episode {episode}: {props.episodes.length>mediaPlayerState.episodeIndex?props.episodes[mediaPlayerState.episodeIndex]['itunes:title']._text.toLowerCase():''}
            </Typography>
          </Slide>
        </Grid>
      </Grid>
    </div>
  );
}

export default MediaPlayerHeader;