import retroReviews from './retroReviewState'

const initialState = {
  email: {
    isLoading: false,
    message: ''
  },
  style: {
    isRetro: false,
    isDrawerOpened: false
  },
  podcast: {
    isLoading: true,
    mediaCount: 4,
    rss: {
      channel: {}
    }
  },
  stats: {
    isLoading: false,
    retroReviews
  }
}

export default initialState;