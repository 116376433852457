const constants = {
    routes: {
        home: '/',
        about: '/about',
        contact: '/contact-us',
        blog: '/blog',
        retroReviews: '/retro-reviews'
    },
    routesArray: [
      {
        path: '/',
        name: 'Home',
        active: true
      },
      {
        path: '/about',
        name: 'About',
        active: true
      },
      {
        path: '/blog',
        name: 'Blog',
        active: true
      },
      {
        path: '/retro-reviews',
        name: 'Retro Reviews',
        active: true
      },
      {
        path: '/contact-us',
        name: 'Contact Us',
        active: true
      },
    ]
};

export default constants;