import types from '../../types';
import initialState from '../../initialState';

function email(state = initialState.email, action) {
  switch (action.type) {
    case types.SEND_START:
      return {
        ...state,
        isLoading: true
      }
    case types.SEND_COMPLETE:
      return {
        ...state,
        isLoading: false
      }
    case types.SEND_ERROR:
      return {
        ...state,
        message: action.message
      }
    default:
      return state
  }
}

export default email;