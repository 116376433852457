import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import constants from '../../constants';
import styles from './app-links.module.css';

const AppLinks = props => {
  return (
    <div className={styles.container}>
      <List>
      { constants.routesArray.filter(route => route.active).map((route,index) => {
          return (
            <Link key={index} to={route.path} onClick={() => props.toggleDrawer()}>
              <ListItem button>
                  <ListItemText className={styles.link}>{route.name}</ListItemText>
              </ListItem>
            </Link>
          )
        })
      }
      </List>
    </div>
  );
}

export default AppLinks;