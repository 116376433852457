import types from '../../types';

export const toggleDrawer = () => {
  return {
    type: types.TOGGLE_DRAWER
  }
}

export const toggleRetro = () => {
  return {
    type: types.TOGGLE_RETRO
  }
}