import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './component/app';
import {BrowserRouter as Router} from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import {podcastOperations} from './redux/ducks/podcast';
import {statsOperations} from './redux/ducks/stats';
import initialState from './redux/initialState';
import properties from './properties';
import * as serviceWorker from './serviceWorker';

const store = configureStore(initialState);
store.dispatch(podcastOperations.getRss(properties.rss));
store.dispatch(statsOperations.getStats());

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
