import About from './about';
import {connect} from 'react-redux';

const mapStateToProps = state => {
  return {
    description: state.podcast.rss.channel.hasOwnProperty('description')?state.podcast.rss.channel.description._cdata:"Loading . . .",
    mailTo: state.podcast.rss.channel.hasOwnProperty('itunes:owner')?state.podcast.rss.channel['itunes:owner']['itunes:email']._text:"Loading . . .",
    author: state.podcast.rss.channel.hasOwnProperty('itunes:author')?state.podcast.rss.channel['itunes:author']._text:"Loading . . .",
    tags: state.podcast.rss.channel.hasOwnProperty('itunes:category')?state.podcast.rss.channel['itunes:category']:[{_attributes:{text:'Loading . . .'}}],
  }
}

export default connect(mapStateToProps)(About);