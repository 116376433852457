import { combineReducers } from 'redux';
import email from './ducks/email';
import podcast from './ducks/podcast';
import stats from './ducks/stats';
import style from './ducks/style';

const reducers = combineReducers({
  email,
  podcast,
  stats,
  style
})

export default reducers;