import axios from 'axios';
import types from '../../types';

export const sendEmail = (email) => dispatch => {
  dispatch(startSend());

  dispatch(sendComplete());
}

const startSend = () => {
  return {
    type: types.SEND_START
  }
}

const sendComplete = () => {
  return {
    type: types.SEND_COMPLETE
  }
}

const sendError = (message) => {
  return {
    type: types.SEND_ERROR,
    message: message
  }
}