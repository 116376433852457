import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import styles from './player.module.css';

class Player extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h6">{'Retro Review Statistics For ' + this.props.player.name}</Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <caption>{'Retro Review statistics for ' + this.props.player.name}</caption>
                        <TableHead>
                            <TableRow>
                                <TableCell>Episode</TableCell>
                                <TableCell colSpan={2} align="center">Movie A (Rating) vs. Movie B (Rating)</TableCell>
                                <TableCell align="right">Rating Used</TableCell>
                                <TableCell align="right">Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                    {
                        this.props.player.episodes.map(episode => {
                            return (
                                <TableRow>
                                    <TableCell component="th" className={styles.left}>{this.props.player[episode].name}</TableCell>
                                    <TableCell align="center">{this.props.player[episode].home + ' (' + this.props.player[episode].homeRating + ')'}</TableCell>
                                    <TableCell align="center">{this.props.player[episode].away + ' (' + this.props.player[episode].awayRating + ')'}</TableCell>
                                    <TableCell align="right">{this.props.player[episode].ratingUsed}</TableCell>
                                    <TableCell align="right">{this.props.player[episode].result>0?"Win":"Loss"}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}

const useStyles = theme => ({
    root: {
        '& > *': {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
        },
    },
});

export default withStyles(useStyles)(Player);