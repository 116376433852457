import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import NewsletterSubscribe from '../newsletter-subscribe';
import properties from '../../properties';
import styles from './contact.module.css';

const Contact = (props) => {
  const handleMailTo = () => {
    window.location.href = 'mailto:' + properties.email;
  }

  return (
    <Container maxWidth="sm" className={styles.container}>
      <Typography variant="h6" gutterBottom>Contact Us</Typography>
      <Typography variant="body1" gutterBottom>
        For podcast guest bookings, podcast collaborations, sponsorships, or any other inquiries,
        please send us an email.
      </Typography>
      <Box className={styles.box}>
        <Button color="default" className={styles.button} onClick={handleMailTo}>mailto : NostalgiaBlastPodcast@gmail.com</Button>
      </Box>
      <Typography variant="body1" gutterBottom>
        If you want to keep up to date with the show, subscribe to our newsletter using the form below. 
        If email's not your thing, use the links in the footer below to follow us on your preferred 
        social media platform.
      </Typography>
      <NewsletterSubscribe/>
    </Container>
  );
}

export default Contact;