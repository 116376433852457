import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import About from '../about';
import Announcment from '../announcement';
import BlogBrowser from '../blog-browser';
import Contact from '../contact';
import MediaBrowser from '../media-browser';
import MediaPlayerHeader from '../media-player-header';
import Header from '../header';
import Footer from '../footer';
import Stats from '../stats';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import loading from '../../images/loading.svg';
import MajorMono from '../../fonts/MajorMonoDisplay-Regular.ttf'
import constants from '../../constants';
import styles from './app.module.css';

const App = props => {

  const majorMono = {
    fontFamily: 'Mono',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      url(${MajorMono}) format('tff')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };

  const theme = createMuiTheme({
    palette: {
      type: useMediaQuery('(prefers-color-scheme: dark)')?'dark':'light',
    },
    typography: {
      fontFamily: 'Montserrat',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [majorMono],
        },
      },
    },
  });

  console.log(props.announcement);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <div className={props.isRetro?[styles.app, styles.appRetro].join(' '):styles.app}>
        {useLocation().pathname === constants.routes.home?<MediaPlayerHeader startGuid={queryString.parse(window.location.search).ep}/>:<Header/>}
        <main className={styles.main}>
          { props.announcement &&
          <Announcment message={props.announcement.description._cdata}/>
          }
          <Route exact={true} path={constants.routes.home}>
          {props.podcast.isLoading
            ?<div><h1 className={styles.loading}>Loading . . .</h1><img className={styles.loadingIcon} src={loading} alt="Loading"></img></div>
            :<MediaBrowser/>
          }
          </Route>
          <Route path={constants.routes.blog}>
            <BlogBrowser/>
          </Route>
          <Route path={constants.routes.retroReviews}>
            <Stats/>
          </Route>
          <Route path={constants.routes.about}>
            <About/>
          </Route>
          <Route path={constants.routes.contact}>
            <Contact/>
          </Route>
        </main>
        <Footer channel={props.podcast.rss.channel}/>
      </div>
    </ThemeProvider>
  );
}

App.propTypes = {
  podcast: PropTypes.object.isRequired
}

export default App;
