import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Media from '../media';
import styles from './media-browser.module.css';

class MediaBrowser extends Component {
    render() {
    
    return (
      <div className={styles.container}>
          { this.props.items.filter(item => item && item.enclosure && item.enclosure._attributes && item.enclosure._attributes.type && item.enclosure._attributes.type === 'audio/mpeg').slice(0,this.props.mediaCount).map( (item, index) => {
              return (
                <Media key={index} item={item} />
              );
            })
          }
          {
            this.props.mediaCount < this.props.items.filter(item => item && item.enclosure && item.enclosure._attributes && item.enclosure._attributes.type && item.enclosure._attributes.type === 'audio/mpeg').length &&
            <Button className={styles.button} color="default" variant="contained" onClick={this.props.onSeeMoreClick}>See More</Button>
          }
      </div>
    );
  }
}

MediaBrowser.propTypes = {
  items: PropTypes.array.isRequired,
  mediaCount: PropTypes.number.isRequired
}

export default MediaBrowser;
