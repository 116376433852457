import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import styles from './blog-post.module.css';

const BlogPost = (props) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  let dateString = '';
  if(props.open) {
    const date = new Date(props.blog.pubDate._text);
    dateString = monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
  }

  return (
    <Backdrop open={props.open} className={styles.backdrop} transitionDuration={500}>
      <Zoom in={props.open}>
        <Paper className={styles.paper}>
          <Box className={styles.header}>
            <IconButton onClick={props.onClose} className={styles.closeButton}>
              <CloseIcon/>
            </IconButton>
          </Box>
          <Box className={styles.title}>
            <Typography variant="h6" color="textSecondary" component="h6" gutterBottom>
                {props.blog && props.blog.title && props.blog.title._text?props.blog.title._text:''}
            </Typography>
          </Box>
          <Grid container alignItems="stretch" className={styles.container}>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary" component="p">
                {props.blog && props.blog.description && props.blog.description._cdata?props.blog.description._cdata:''}
              </Typography>
            </Grid>
          </Grid>
          <Box className={styles.footer}>
            <Divider/>
            <Typography variant="subtitle1" color="textSecondary" component="span">
              {dateString}
            </Typography>
          </Box>
        </Paper>
      </Zoom>
    </Backdrop>
  );
}

export default BlogPost;