const GET_RSS = 'getRss';
const GET_STATS = 'getStats';
const INCREMENT_MEDIA_COUNT = 'incrementMediaCount';
const REQUEST_RSS = 'requestRss';
const RECEIVE_RSS = 'receiveRss';
const REQUEST_STATS = 'requestStats';
const RECEIVE_STATS = 'receiveStats';
const SEND_COMPLETE = 'sendComplete';
const SEND_ERROR = 'sendError';
const SEND_START = 'startSend';
const TOGGLE_DRAWER = 'toggleDrawer';
const TOGGLE_RETRO = 'toggleRetro';

export default {
  GET_RSS,
  GET_STATS,
  INCREMENT_MEDIA_COUNT,
  REQUEST_RSS,
  RECEIVE_RSS,
  REQUEST_STATS,
  RECEIVE_STATS,
  SEND_COMPLETE,
  SEND_ERROR,
  SEND_START,
  TOGGLE_DRAWER,
  TOGGLE_RETRO
};